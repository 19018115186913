import React from "react";
import { PageWrapperDark } from "../components/page-wrapper";
import Button from "@material-ui/core/Button";
import emailjs from "emailjs-com";
import { useForm } from "react-hook-form";
import "../styles/pages/contact.scss";

function Contact() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  async function sendEmail(e) {
    console.log(e);
    try {
      await emailjs.sendForm(
        "service_5ws13o6",
        "template_lwpq1xq",
        e.target,
        "user_V0Llvcfbr323IWkGMbnZU"
      );
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <PageWrapperDark>
      <main>
        <div id="contact-section" className="container">
          <h1>
            Get in touch<span>.</span>
          </h1>
          <div id="contact-content">
            <form
              id="contact-form"
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(() => sendEmail(e))();
              }}
            >
              <input
                type="text"
                name="name"
                placeholder="name"
                {...register("name", { required: true })}
              />
              {errors.name && (
                <span class="error">Don't forget your name!</span>
              )}
              <input
                type="text"
                name="email"
                placeholder="email"
                {...register("email", { required: true })}
              />
              {errors.email && (
                <span class="error">Don't forget your email!</span>
              )}
              <textarea
                name="message"
                {...register("message", { required: true })}
                id="message"
                rows="10"
              />
              {errors.message && (
                <span class="error">Don't forget your Message!</span>
              )}
              <Button id="submit" type="submit">
                Send
              </Button>
            </form>
            <div id="banner">
              <section>
                <h2>Contact</h2>
                <p>p: 626-624-6565</p>
                <p>e: josec@westcoast-metalroofing.com</p>
              </section>
              <section>
                <h2>Business Info</h2>
                <p>Mon - Fri; Hours</p>
                <p>15443 Proctor Ave. Space B</p>
                <p>City of Industry, 91745 CA</p>
              </section>
            </div>
          </div>
        </div>
      </main>
    </PageWrapperDark>
  );
}

export default Contact;
